// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Users/daniel/Projects/albedo_og/albedo_sports/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/Users/daniel/Projects/albedo_og/albedo_sports/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-impressum-js": () => import("/Users/daniel/Projects/albedo_og/albedo_sports/src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("/Users/daniel/Projects/albedo_og/albedo_sports/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pointofsale-js": () => import("/Users/daniel/Projects/albedo_og/albedo_sports/src/pages/pointofsale.js" /* webpackChunkName: "component---src-pages-pointofsale-js" */),
  "component---src-pages-produkte-js": () => import("/Users/daniel/Projects/albedo_og/albedo_sports/src/pages/produkte.js" /* webpackChunkName: "component---src-pages-produkte-js" */),
  "component---src-pages-produkte-energieriegel-js": () => import("/Users/daniel/Projects/albedo_og/albedo_sports/src/pages/produkte/energieriegel.js" /* webpackChunkName: "component---src-pages-produkte-energieriegel-js" */),
  "component---src-pages-produkte-energy-chews-js": () => import("/Users/daniel/Projects/albedo_og/albedo_sports/src/pages/produkte/energy-chews.js" /* webpackChunkName: "component---src-pages-produkte-energy-chews-js" */),
  "component---src-pages-produkte-gunpowder-drinks-js": () => import("/Users/daniel/Projects/albedo_og/albedo_sports/src/pages/produkte/gunpowder-drinks.js" /* webpackChunkName: "component---src-pages-produkte-gunpowder-drinks-js" */),
  "component---src-pages-produkte-proteinriegel-js": () => import("/Users/daniel/Projects/albedo_og/albedo_sports/src/pages/produkte/proteinriegel.js" /* webpackChunkName: "component---src-pages-produkte-proteinriegel-js" */),
  "component---src-pages-produkte-quickmix-shakes-js": () => import("/Users/daniel/Projects/albedo_og/albedo_sports/src/pages/produkte/quickmix-shakes.js" /* webpackChunkName: "component---src-pages-produkte-quickmix-shakes-js" */),
  "component---src-pages-produkte-yippee-bars-js": () => import("/Users/daniel/Projects/albedo_og/albedo_sports/src/pages/produkte/yippee-bars.js" /* webpackChunkName: "component---src-pages-produkte-yippee-bars-js" */)
}

exports.data = () => import("/Users/daniel/Projects/albedo_og/albedo_sports/.cache/data.json")

